import Percents from "./../percent.json";
import Percents20 from "./../percent20.json";

export const GetPercent = (amount, type, descount) => {
    try {
        if (descount === 10) {
            let percent = Percents.percents.filter((item) => {
                return item.value === amount;
            });
            //console.log(percent[0].profits[type]);
            return percent[0].profits[type];
        } else {
            let percent = Percents20.percents.filter((item) => {
                return item.value === amount;
            });
            return percent[0].profits[type];
        }
    } catch (e) {
        return 0;
    }
};
