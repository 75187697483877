import React, { Component } from "react";

export default class index extends Component {
    render() {
        return (
            <div id="booking-confirm" className="booking-form">
                <div id="top-bar" className="full align-self-bottom">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x grid-padding-y">
                            <div className="small-12 medium-6 large-6 cell small-only-text-center  medium-text-left large-text-left">
                                <a href="/reserve">
                                    <img
                                        height="30"
                                        src={process.env.PUBLIC_URL + "/rci-logo.png"}
                                        alt="RCI"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full" id="breadcrumbs">
                    <div className="grid-container">
                        <div className="contenedor-flex grid-x grid-padding-x"></div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y form-traveler">
                        <div className="small-12  cell">
                            <div className="grid-x">
                                <div className="small-12 medium-8">
                                    <h1>Reservation Completed Successfully!</h1>
                                    <h2>Reservation Completed #TR198-38545</h2>
                                </div>
                                <div className="smaa-12 medium-4 text-right">
                                    <h3>Price $55.00</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="small-12  cell">
                            <h4>Customer information:</h4>
                            <div id="fullnameTraveler">
                                <p>
                                    <b>Full name: </b> <span className="fullname">Full Name</span>
                                </p>
                            </div>
                            <div id="phoneTraveler">
                                <p>
                                    <b>Phone: </b>
                                    <span className="phone">612 122 90 09</span>
                                </p>
                            </div>
                            <div id="emailTraveler">
                                <p>
                                    <b>E-mail: </b>
                                    <span className="email">email@correo.com</span>
                                </p>
                            </div>
                            <h5>Transport Selected:</h5>
                            <p>
                                <b>Tranport Vehicle:</b> <span className="">Sedan</span>
                            </p>
                            <p>
                                <b>Trip Type:</b> <span className="">One Way Trip</span>
                            </p>
                            <p>
                                <b>Passengers Number:</b> <span className="pax_num">5</span>
                            </p>
                            <p>
                                <b>Transport Selected:</b> <span className="transport">Sedan</span>
                            </p>
                            <p>
                                <b>Pickup Location:</b> <span className="transport">Zona X</span>
                            </p>
                            <p>
                                <b>Destination:</b> <span className="transport">Zona Y</span>
                            </p>
                            <hr />
                        </div>
                    </div>
                </div>
                <footer className="grid-containerfull ">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                            <div className="small-12 medium-8 cell medium-text-left">
                                <p>
                                    Tranroute Cabo Transportation &copy; 2020 <br /> Los Cabos, Baja
                                    California Sur, México
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
