import React, { Component } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSet: [],
            dateColumns: ["REGISTRATION_DATE", "ARRIVAL_DATE", "DEPARTURE_DATE"],
        };
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            dataSet: this.parseFileContent(nextProps.data),
        });
    };

    parseFileContent = (reservations) => {
        if (reservations.length > 0) {
            let columnNames = Object.keys(reservations[0]);
            columnNames.shift();
            columnNames.shift();

            let columns = [];
            columnNames.forEach((item) => {
                columns.push(item.split("_").join(" "));
            });

            let data = [];
            let { dateColumns } = this.state;

            try {
                reservations.forEach((_item, index) => {
                    let sub_data = [];
    
                    columnNames.forEach((key) => {
                        let _val = { value: null };
    
                        if (dateColumns.includes(key)) {
                            if (_item[key] !== "N/A" || _item[key] !== "Invalid date" || _item[key] !== "Invalid time" || _item[key] !== "CANCELLED!" || _item[key] !== "CANCELLED" || _item[key] !== null || _item[key] !== "")  {
                                let auxDate = _item[key].trim().split(" ")[0];
                                if (key === "REGISTRATION_DATE") {
                                    //console.log("REGISTRATION_DATE", index, _item[key], "=>", moment(auxDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                                    _val.value = moment(auxDate, "DD/MM/YYYY").format("MM/DD/YYYY");
                                } else {
                                    _val.value = moment(auxDate, "MM/DD/YYYY").format("MM/DD/YYYY");
                                }
                            } else {
                                _val.value = String(_item[key]);
                            }
                        } else {
                            _val.value = String(_item[key]);
                        }
                        sub_data.push(_val);
                    });
    
                    data.push(sub_data);
                });
            } catch (error) {
                console.error(error)
            }


            return [
                {
                    columns,
                    data,
                },
            ];
        } else {
            let columns = [],
                data = [];
            return [
                {
                    columns,
                    data,
                },
            ];
        }
    };

    render() {
        const { dataSet } = this.state;

        //console.log("dataSet", dataSet);
        return (
            <div className="excel-download-btn">
                <ExcelFile
                    element={
                        <button type="button" className="button btn-green">
                            <FontAwesomeIcon icon="download" /> Export Excel
                        </button>
                    }>
                    <ExcelSheet dataSet={dataSet} name="Reservations" />
                </ExcelFile>
            </div>
        );
    }
}

export default ExcelExport;
