import React, { Component, Fragment } from "react";
import Header from "./../utils/header";
import Footer from "./../utils/footer";
import { validateToken, validateUserLvl } from "../../session/session_controller";
import { getData, getFilterData } from "./../../services/reservation";
import { GetPercent } from "./../../services/rates_percent";
import { getRatesControl } from "../../api_controller/rcontrol_controller";
import TableFees from "./tablefees";
import LoadingOverlay from "react-loading-overlay";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import moment from "moment";
import ExcelExport from "./excelexport";

const loaderStyles = {
    overlay: (base) => ({
        ...base,
        background: "rgba(64, 64, 66, 0.9)",
    }),
};

export default class index extends Component {
    constructor() {
        super();
        this.state = {
            rates_control: [],
            date_init: "",
            date_end: "",
            isloading: false,
            fees: [],
        };
        validateToken();
        validateUserLvl();

        getRatesControl().then((resp) => {
            this.setState({ rates_control: resp.data.results }, () => {
                //console.log(resp.data.results);
                getData().then((res) => {
                    let reserves = [];
                    res.data.results.forEach((item) => {
                        let cost_validate = this.parseNaturalCost(item.ID_RESERVATION, item.COSTO);
                        let isDiscount = "";

                        if (cost_validate.TOTAL == item.COSTO) {
                            isDiscount = "No";
                        } else {
                            isDiscount = `Yes, ${cost_validate.DESCUENTO}%`;
                        }

                        let r = {
                            ID: item.ID,
                            id: item.ID_RESERVATION,
                            reservation_date: item.FECHA_REGISTRO,
                            reservation_num: item.ID,
                            cost: `$ ${item.COSTO} USD`,
                            rci_profit: `$ ${this.hardCodeParseDiscount(
                                GetPercent(cost_validate.TOTAL, "rci", cost_validate.DESCUENTO),
                                isDiscount,
                                cost_validate.DESCUENTO
                            )} USD`,
                            trans_profit: `$ ${this.hardCodeParseDiscount(
                                GetPercent(cost_validate.TOTAL, "trans", cost_validate.DESCUENTO),
                                isDiscount,
                                cost_validate.DESCUENTO
                            )} USD`,
                            auto_payment: item.PAGO,
                            isDiscount,
                            real_cost: `$ ${cost_validate.NATURAL_COST} USD`,
                        };
                        reserves.push(r);
                    });
                    this.setState({ fees: reserves });
                });
            });
        });
    }

    parseNaturalCost = (id_reservation, cost) => {
        const { rates_control } = this.state;
        let natural_cost;
        let _item = null;

        for (let index = 0; index < rates_control.length; index++) {
            const element = rates_control[index];
            if (parseInt(id_reservation) == element.ID_RESERVATION) {
                _item = element;
                break;
            }
        }

        if (_item != null) {
            natural_cost = _item;
            natural_cost.NATURAL_COST = _item.TOTAL;
        } else {
            natural_cost = { TOTAL: cost, DESCUENTO: 0, NATURAL_COST: cost };
        }

        return natural_cost;
    };

    handleDateinChange = (date) => {
        this.setState({ date_init: date });
    };

    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        //Validar que no vengan vacias
        let validate = true;
        if (this.state.date_init === "") {
            validate = false;
        }
        if (this.state.date_end === "") {
            validate = false;
        }

        if (validate) {
            console.log("Las fechas son correctas.");
            this.setState({ isloading: true });
            getFilterData(
                moment(this.state.date_init).format("DD/MM/YYYY"),
                moment(this.state.date_end).format("DD/MM/YYYY")
            ).then((res) => {
                let reserves = [];
                res.data.results.forEach((item) => {
                    let cost_validate = this.parseNaturalCost(item.ID_RESERVATION, item.COSTO);
                    let isDiscount = "";

                    if (cost_validate.TOTAL == item.COSTO) {
                        isDiscount = "No";
                    } else {
                        isDiscount = `Yes, ${cost_validate.DESCUENTO}%`;
                    }

                    let r = {
                        ID: item.ID,
                        id: item.ID_RESERVATION,
                        reservation_date: item.FECHA_REGISTRO,
                        reservation_num: item.ID,
                        cost: `$ ${item.COSTO} USD`,
                        rci_profit: `$ ${this.hardCodeParseDiscount(
                            GetPercent(cost_validate.TOTAL, "rci", cost_validate.DESCUENTO),
                            isDiscount,
                            cost_validate.DESCUENTO
                        )} USD`,
                        trans_profit: `$ ${this.hardCodeParseDiscount(
                            GetPercent(cost_validate.TOTAL, "trans", cost_validate.DESCUENTO),
                            isDiscount,
                            cost_validate.DESCUENTO
                        )} USD`,
                        auto_payment: item.PAGO,
                        isDiscount,
                        real_cost: `$ ${cost_validate.NATURAL_COST} USD`,
                    };
                    reserves.push(r);
                });
                this.setState({ fees: reserves }, () => {
                    this.setState({ isloading: false });
                });
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error al buscar...",
                text: "Por favor, revise que ambas fechas sean correctas.",
            });
        }
    };

    hardCodeParseDiscount = (rate, isDiscount, descount) => {
        //console.warn(rate);
        if (isDiscount != "No") {
            rate = rate.toFixed(2);
            return rate;
        } else {
            return rate;
        }
    };

    render() {
        return (
            <Fragment>
                <div id="booking-form" className="booking-form">
                    <Header></Header>
                    <div className="full" id="breadcrumbs">
                        <div className="grid-container">
                            <div className="contenedor-flex grid-x grid-padding-x"></div>
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="grid-x grid-padding-x grid-padding-y"></div>
                        <LoadingOverlay
                            active={this.state.isloading}
                            spinner
                            styles={loaderStyles}
                            text="We are processing your request...">
                            <div className="grid-x grid-padding-x grid-padding-y">
                                <div className="small-5 cell align-self-center pl-3">
                                    <h1>REGISTERED FEES</h1>
                                </div>
                                <div className="medium-2 cell">
                                    <label>
                                        Initial Date
                                        <DatePicker
                                            selected={this.state.date_init}
                                            onChange={this.handleDateinChange}
                                            maxDate={this.state.date_end}
                                            required></DatePicker>
                                    </label>
                                </div>
                                <div className="medium-2 cell">
                                    <label>
                                        Date End
                                        <DatePicker
                                            selected={this.state.date_end}
                                            minDate={this.state.date_init}
                                            onChange={this.handleDateEndChange}
                                            required></DatePicker>
                                    </label>
                                </div>
                                <div className="medium-1 cell align-self-bottom d-flex justify-content-end">
                                    <button
                                        type="submit"
                                        className="button btn-primary"
                                        onClick={this.handleSubmit}>
                                        SEARCH
                                    </button>
                                </div>
                                <div className="medium-2 cell align-self-bottom d-flex justify-content-end">
                                    <ExcelExport data={this.state.fees}></ExcelExport>
                                </div>
                            </div>
                        </LoadingOverlay>
                        <div className="grid-x grid-padding-x grid-padding-y b-white">
                            <div className="small-12 cell">
                                <TableFees records={this.state.fees}></TableFees>
                            </div>
                        </div>
                    </div>

                    <Footer></Footer>
                </div>
            </Fragment>
        );
    }
}
