import axios from "axios";
import configs from "../configs/config";
import moment from "moment";

export const ApiCreateReservation = (newData) => {
    return axios.post(`${configs.URL_API_BASE}/reservation`, newData);
};

export const ApiPutReservation = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/reservation/${id}`, data);
};

export const ApiGetRates = (zone_pick, zone_destination, unit) => {
    return axios.get(`${configs.URL_API_BASE}/rates?zone_pick=${zone_pick}&zone_destination=${zone_destination}&unit=${unit}`);
};

export const getData = (filter) => {
    return axios.get(
        `${configs.URL_API_BASE}/reservation?date_init=${moment().format("01/01/2019")}&date_end=${moment().format("DD/MM/YYYY")}&filter=${filter}`
    );
};

export const getFilterData = (di, de, filter) => {
    return axios.get(`${configs.URL_API_BASE}/reservation?date_init=${di}&date_end=${de}&filter=${filter}`);
};

export const getDataById = (id) => {
    return axios.get(`${configs.URL_API_BASE}/reservation/${id}`);
};
