import React, { Component } from "react";

class footer extends Component {
    render() {
        return (
            <footer className="grid-containerfull ">
                <br />
                <div className="grid-container">
                    <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                        <div className="small-12 medium-8 cell medium-text-left">
                            <p>
                                Transroute Cabo Transportation &copy; <br /> 2023 - Los Cabos, Baja California
                                Sur, México
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default footer;
