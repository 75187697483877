import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";

class TableFees extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "reservation_date",
                text: "RESERVATION DATE",
                className: "reservation_date",
                align: "center",
                sortable: true,
            },
            {
                key: "reservation_num",
                text: "RESERVATION NUMBER",
                className: "reservation_num",
                align: "center",
                sortable: true,
            },
            {
                key: "real_cost",
                text: "REAL COST",
                className: "real_cost",
                align: "center",
                sortable: true,
            },
            {
                key: "cost",
                text: "TOTAL COST",
                className: "cost",
                align: "center",
                sortable: true,
            },
            {
                key: "isDiscount",
                text: "DISCOUNT",
                className: "isDiscount",
                align: "center",
                sortable: true,
            },
            {
                key: "rci_profit",
                text: "FEES RCI",
                className: "fees_rci",
                align: "center",
                sortable: true,
            },
            {
                key: "trans_profit",
                text: "FEES TRANSROUTE",
                className: "fees_transroute",
                align: "center",
                sortable: true,
            },
            {
                key: "auto_payment",
                text: "AUTH CODE",
                className: "auto_payment",
                align: "center",
                sortable: true,
            },
        ];
        this.config = {
            page_size: 50,
            length_menu: [50, 100, 200],
            button: { excel: false },
            //sort: {column:"", order:"desc"}
        };
        this.state = {
            records: this.props.records,
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        };
    };

    render() {
        return (
            <ReactDatatable
                className="table stack b-white hover table-bordered"
                key={"table22"}
                config={this.config}
                records={this.state.records}
                columns={this.columns}
            />
        );
    }
}

export default TableFees;
